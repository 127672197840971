const Notifications = (function($) {
  // eslint-disable-next-line no-shadow
  function Notifications(dismissNotificationUrl, obtrusiveNotificationsList) {
    let self = this;
    self.obtrusiveNotificationsList = obtrusiveNotificationsList;
    self.dismissNotificationUrl = dismissNotificationUrl;
    self.confirmButtonColor = '#0b563e';
    self.showObtrusiveNotifications();
    self.setupDismissingUnobtrusiveNotification();
    self.setupRecentNotifications();
  }

  Notifications.prototype = {

    setupRecentNotifications() {
      let self = this;

      $('#recent-notification a').on('click', function(e) {
        let $notificationLink = $(this),
            title = window.baseAPI.decode_escapejs($notificationLink.data('title')),
            html = window.baseAPI.decode_escapejs($notificationLink.data('content'));

        e.preventDefault();
        window.swal2_wrapper({
          title,
          html,
          confirmButtonText: 'Close',
          confirmButtonColor: self.confirmButtonColor,
          allowOutsideClick: true,
        });
      });
    },

    setupDismissingUnobtrusiveNotification() {
      let self = this;

      $('.unobtrusive-notification button.close').on('click', function() {
        let $alert = $(this).parent('div'),
            url = self.dismissNotificationUrl.replace('/1/', `/${$alert.data('id')}/`);

        $.getJSON(url, () => {
          $alert.alert('close');
        });
      });
    },

    showObtrusiveNotifications() {
      let self = this,

        // eslint-disable-next-line no-unused-vars
          notification = window.base_js.swal.mixin();

      if (self.obtrusiveNotificationsList.length > 1) {
        window.swal2_wrapper({
          confirmButtonText: 'OK',
          preConfirm() {
            // eslint-disable-next-line no-unused-vars
            return new Promise((resolve, reject) => {
              let step = 0;
              if (window.base_js.swal.getQueueStep()) {
                step = window.base_js.swal.getQueueStep();
              }
              if (self.obtrusiveNotificationsList[step]) {
                let url = self.dismissNotificationUrl.replace('/1/', `/${self.obtrusiveNotificationsList[step].id}/`);
                // eslint-disable-next-line no-empty-function
                $.getJSON(url, () => {});
                // if we want to display an error we should call
                // base_js.swal.showValidationMessage('whatever')
              }
              resolve();
            });
          },
          notifications: self.obtrusiveNotificationsList,
        });
      } else if (self.obtrusiveNotificationsList.length === 1) {
        window.swal2_wrapper({
          confirmButtonText: 'OK',
          preConfirm() {
            return new Promise((resolve) => {
              if (self.obtrusiveNotificationsList[0]) {
                let url = self.dismissNotificationUrl.replace('/1/', `/${self.obtrusiveNotificationsList[0].id}/`);
                // eslint-disable-next-line no-empty-function
                $.getJSON(url, () => {});
              }
              resolve();
            });
          },
          title: self.obtrusiveNotificationsList[0].title,
          html: self.obtrusiveNotificationsList[0].html,
        });
      }
    },

  };

  return Notifications;
}(window.$));

window.Notifications = Notifications;
